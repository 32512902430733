import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFileAudio } 	from '@fortawesome/free-regular-svg-icons/faFileAudio'
import { faBookmark } 	from '@fortawesome/free-regular-svg-icons/faBookmark'

import { faYoutube } 	from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faSpotify } 	from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faApple } 		from '@fortawesome/free-brands-svg-icons/faApple';
import { faAmazon } 	from '@fortawesome/free-brands-svg-icons/faAmazon';
import { faInstagram } 	from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faMusic } 		from '@fortawesome/free-solid-svg-icons/faMusic';
import { faFacebook } 	from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTiktok }		from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faXTwitter } 	from '@fortawesome/free-brands-svg-icons/faXTwitter';

import { Row, Col, Navbar, Nav, Tab, Toast, Image, Modal } from 'react-bootstrap';

// import { useTour } from './context/tour-context';
import KuulaProject from './components/KuulaProject';
// import SiteItem from './components/SiteItem';

import './styles/App.css';
import './styles/TabStyles.css';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

const site_items = [
	{ id: 1,   hotspot_name: "AC01", img_loc: "AC01.png", title: "Black Trash Bag", description: "", visible: false },
	{ id: 2,   hotspot_name: "AC02", img_loc: "AC02.png", title: "Clear Trash Bag", description: "", visible: false },
	{ id: 3,   hotspot_name: "AC03", img_loc: "AC03.png", title: "Whip Cream", 		description: "", visible: false },
	{ id: 4,   hotspot_name: "AC04", img_loc: "AC04.png", title: "50-gallon Drum", 	description: "", visible: false },
	{ id: 5,   hotspot_name: "AC05", img_loc: "AC05.png", title: "Banana Peel", 	description: "", visible: false },
	{ id: 6,   hotspot_name: "AC06", img_loc: "AC06.png", title: "Cardboard Box", 	description: "", visible: false }
	
	/*
	{ id: 1,   hotspot_name: "AC001", img_loc: "easter_egg__0000_CB-Radio.png", 		title: "CB Radio", 			description: "", visible: false },
	{ id: 2,   hotspot_name: "AC002", img_loc: "easter_egg__0001_TV.png", 				title: "TV", 				description: "", visible: false },
	{ id: 3,   hotspot_name: "AC003", img_loc: "easter_egg__0002_Map.png", 				title: "Map", 				description: "", visible: false },
	{ id: 4,   hotspot_name: "AC004", img_loc: "easter_egg__0003_Mic.png", 				title: "Mic", 				description: "", visible: false },
	{ id: 5,   hotspot_name: "AC005", img_loc: "easter_egg__0004_drum-kit.png", 		title: "Drum Kit", 			description: "", visible: false },
	{ id: 6,   hotspot_name: "AC006", img_loc: "easter_egg__0005_Tire.png", 			title: "Tire", 				description: "", visible: false },
	{ id: 7,   hotspot_name: "AC007", img_loc: "easter_egg__0006_Elephant.png", 		title: "Elephant", 			description: "", visible: false },
	{ id: 8,   hotspot_name: "AC008", img_loc: "easter_egg__0007_shirt-3.png", 			title: "Shirt 3", 			description: "", visible: false },
	{ id: 9,   hotspot_name: "AC009", img_loc: "easter_egg__0008_shirt-4.png", 			title: "Shirt 4", 			description: "", visible: false },
	{ id: 10,  hotspot_name: "AC010", img_loc: "easter_egg__0009_shirt-1.png", 			title: "Shirt 1", 			description: "", visible: false },
	{ id: 11,  hotspot_name: "AC011", img_loc: "easter_egg__0010_guitar.png", 			title: "Guitar", 			description: "", visible: false },
	{ id: 12,  hotspot_name: "AC012", img_loc: "easter_egg__0011_Trash.png", 			title: "Trash", 			description: "", visible: false },
	{ id: 13,  hotspot_name: "AC013", img_loc: "easter_egg__0007_shirt-3.png", 			title: "Shirt 2", 			description: "", visible: false },
	// { id: 13,  hotspot_name: "BC013", img_loc: "easter_egg__0012_Background.png", 	title: "Background", 		description: "", visible: false },
	{ id: 14,  hotspot_name: "AC014", img_loc: "easter_egg__0013_Barrel.png", 			title: "Barrel", 			description: "", visible: false },
	{ id: 15,  hotspot_name: "AC015", img_loc: "easter_egg__0014_ASK-ALICE.png", 		title: "ASK ALICE", 		description: "", visible: false },
	{ id: 16,  hotspot_name: "AC016", img_loc: "easter_egg__0015_Right-Speaker.png", 	title: "Right Speaker", 	description: "", visible: false },
	{ id: 17,  hotspot_name: "AC017", img_loc: "easter_egg__0016_Center-speaker.png", 	title: "Center Speaker",	description: "", visible: false },
	{ id: 18,  hotspot_name: "AC018", img_loc: "easter_egg__0017_LEFT-SPEAKER.png", 	title: "Left Speaker", 		description: "", visible: false },
	{ id: 19,  hotspot_name: "AC019", img_loc: "easter_egg__0018_KEEP-OUT.png", 		title: "KEEP OUT", 			description: "", visible: false },
	{ id: 20,  hotspot_name: "AC020", img_loc: "easter_egg__0019_RADIO.png", 			title: "Radio", 			description: "", visible: false },
	{ id: 21,  hotspot_name: "AC021", img_loc: "easter_egg__0020_MARKER.png", 			title: "Marker", 			description: "", visible: false }
	*/
]

const dsp_links = [
	{
		title: 'Youtube',
		link: "https://youtube.com/subscription_center?add_user=officialalicecooper",
		icon: faYoutube,
		from: 'fontawesome'
	},
	{
		title: 'Spotify',
		link: "https://open.spotify.com/artist/3EhbVgyfGd7HkpsagwL9GS",
		icon: faSpotify,
		from: 'fontawesome'
	},
	{
		title: 'Apple Music',
		link: "https://itunes.apple.com/ca/artist/alice-cooper/393703",
		icon: faApple,
		from: 'fontawesome'
	},
	{
		title: 'Pandora',
		link: "https://www.pandora.com/artist/alice-cooper/AR3V6m4Pmrxmg9q",
		icon: faMusic,
		from: 'fontawesome'
	},
	{
		title: 'Amazon Music',
		link: "https://www.amazon.com/Alice-Cooper/e/B000AP9PB0",
		icon: faAmazon,
		from: 'fontawesome'
	}
]

const socials = [
	{
		link: "https://www.instagram.com/alicecooper",
		icon: faInstagram,
		from: 'fontawesome'
	},
	{
		link: "https://www.facebook.com/AliceCooper/",
		icon: faFacebook,
		from: 'fontawesome'
	},
	{
		link: "https://x.com/AliceCooper",
		icon: faXTwitter,
		from: 'fontawesome'
	},
	{
		link: "https://www.tiktok.com/@alicecooperofficial",
		icon: faTiktok,
		from: 'fontawesome'
	}
]

export default function App() {
	
	const [ show, setShow ] = useState(true);
	const [ firstLoad, setFirstLoad ] = useState(true);

	const [ toastShow, setToastShow ] = useState(false);
	const [ toastList, setToastList ] = useState([]);

	const [ offcanvas, setOffcanvas ] = useState('');
	const [ tabSelected, setTab ] = useState('audio');

	const [ items, setItems ] = useState([ ...site_items ]);
	const [ itemCount, setItemCount ] = useState(0);
	
	const [ userWon, setUserWon ] = useState(false);	// whether or not the user has won already
	const [ showWin, setShowWin ] = useState(false);	// show/hide the form modal

	const [ hotspot, setSelectedHotspot ] = useState('');

	function toggleCanvas() {
		if ( offcanvas === '' )
			setOffcanvas('show')
		else 
			setOffcanvas('')
	}

	useEffect(() => {
		
		if ( localStorage.getItem('eggs') ) {
			let count = 0;
			var localEggs = JSON.parse(localStorage.getItem('eggs'));
			var updated_items = localEggs?.map(item => {
				if (item.visible) count++;
				return item;
			})
			setItemCount(count);
			setItems([ ...updated_items ])

			// check if all items were found, display the discount code
			if ( count === site_items.length ) {
				setUserWon(true);
			}
		}

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

		KuulaPlayerAPI.addEventListener("hotspot", function(e) {
			setSelectedHotspot(e.data.name);
		});

	}, []);

	useEffect(() => {

		if ( hotspot !== '' ) {
			// check if item clicked on
			let new_count = itemCount;
			
			var new_toast = {}
			var new_items = items.map(item => {
				if ( item.hotspot_name === hotspot && !item.visible ) {
					new_count++;
					// add item to toast list
					new_toast = {
						'id': item.id,
						'message': 'Hidden Item Found!',
						'item_name': item.title,
						'img': item.img_loc
					}
					return { ...item, visible: true }
				}
				return { ...item };  
			});

			if ( new_count > itemCount ) {
				setItemCount(new_count);
				setItems([ ...new_items ]);
				localStorage.setItem('eggs', JSON.stringify(new_items))

				setToastList(prevList => ([
					...prevList,
					new_toast
				]))
			}

			// check if all items were found, display the discount code
			if ( new_count === site_items.length && !userWon ) {
				setUserWon(true);
				setShowWin(true)
			}
		}

	}, [hotspot])

	function clearFoundItems() {
		localStorage.removeItem('eggs');
		localStorage.setItem('eggs', JSON.stringify([ ...site_items ]));
		setItemCount(0);
		setItems([ ...site_items ]);
		setUserWon(false);
		setSelectedHotspot('')
	}

	function removeToastFromList(toast_id) {
		var removeItemIndex = toastList.findIndex(toast => toast.id === toast_id);
		var newToastList = [ ...toastList ];
		newToastList.splice(removeItemIndex, 1);
		setToastList(newToastList);
	}

	function handleClose() {
		setShow(false);
		if ( firstLoad ) {
			setFirstLoad(false);
		}
	}

	function handleCloseWin() {
		setShowWin(false);
	}

	function showInstructions(e) {
		e.preventDefault();
		setShow(true);
	}
	
	useEffect(() => {
		
		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

	}, []);

	return (
		<div className="App">

			<Modal show={show} onHide={handleClose}>
				<Modal.Body>
					<img src={`images/welcome-img.png`} alt="Instructions" style={{ width: '100%' }} />
				</Modal.Body>
				<Modal.Footer style={{ textAlign: 'center' }}>
					<button type="button" className="btn" style={{ backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', fontSize: '1.5em', border: 'solid 1px #000000' }} onClick={handleClose}>
						{firstLoad ? 'Enter!' : 'Close'}
					</button>
				</Modal.Footer>
			</Modal>

			<Modal show={showWin} backdrop="static" onHide={handleCloseWin}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div id="mlb2-16500524" className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-16500524">
						<div className="ml-form-align-center ">
							<div className="ml-form-embedWrapper embedForm">
								<div className="ml-form-embedBody ml-form-embedBodyDefault row-form">
									<div className="ml-form-embedContent">
										<h4>Congratulations! You found the treasure!</h4>
										<p>Digging through Trash has its perks. Enter for your chance to win one pair of tickets (two) to see Alice Cooper and Rob Zombie on the Freaks on Parade tour in North America.</p>
									</div>
									<form className="ml-block-form" action="https://assets.mailerlite.com/jsonp/424647/forms/127132195617769445/subscribe" data-code="" method="post" target="_blank">
										<div className="ml-form-formContent">
											<div className="ml-form-fieldRow ">
												<div className="ml-field-group ml-field-name">
													<input aria-label="name" type="text" className="form-control" data-inputmask="" name="fields[name]" placeholder="Name" autoComplete="given-name" />
												</div>
											</div>
											<div className="ml-form-fieldRow ">
												<div className="ml-field-group ml-field-last_name">
													<input aria-label="last_name" type="text" className="form-control" data-inputmask="" name="fields[last_name]" placeholder="Last name" autoComplete="family-name" />
												</div>
											</div>
											<div className="ml-form-fieldRow ">
												<div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
													<input aria-label="email" aria-required="true" type="email" className="form-control" data-inputmask="" name="fields[email]" placeholder="Email" autoComplete="email" />
												</div>
											</div><div className="ml-form-fieldRow ml-last-item">
												<div className="ml-field-group ml-field-z_i_p">
													<input aria-label="z_i_p" type="text" className="form-control" data-inputmask="" name="fields[z_i_p]" placeholder="Zip" autoComplete="" />
												</div>
											</div>
										</div>
										<input type="hidden" name="ml-submit" value="1" />
										<div className="ml-form-embedSubmit">
											<button type="submit" className="primary">ENTER NOW</button>
											<button disabled="disabled" style={{ display: 'none' }} type="button" className="loading">
												<div className="ml-form-embedSubmitLoad"></div>
												<span className="sr-only">Loading...</span>
											</button>
										</div>
										<input type="hidden" name="anticsrf" value="true" />
									</form>
								</div>
								<div className="ml-form-successBody row-success" style={{ display: 'none' }}>
									<div className="ml-form-successContent">
										<h4>Thank you!</h4>
										<p>You have successfully joined our subscriber list.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Navbar variant="dark" bg="transparent" fixed="bottom" expand={false} defaultExpanded={false}>
				<Navbar.Toggle className="p-0 border-0" data-toggle="offcanvas" onClick={toggleCanvas} />
				<Navbar.Collapse id="offcanvas_navigation">
					<Tab.Container activeKey={tabSelected} onSelect={(k) => setTab(k)}>
						<Row id="tour-title-row">
							<Col className="text-center">
								<div className="d-flex justify-content-around mt-2 ms-0">
									{socials.map((social, idx) => (
										<div key={idx}>
											{social.from === 'fontawesome' ? (
												<a href={social.link} target="_blank" className="d-block text-white" rel="noreferrer">
													<FontAwesomeIcon icon={social.icon} height={"25px"} className="mx-4" />
												</a>
											) : (
												<a href={social.link} target="_blank" className="d-block text-white" rel="noreferrer">
													<Image className="mx-4" src={social.icon} alt="icon" width={20} height={20} />
												</a>
											)}
										</div>
									))}
								</div>
								<img src={`images/AliceCooper.png`} alt="Alice Cooper" style={{ width: '150px' }} className="rounded" />
							</Col>
						</Row>
						<Row id="tab-row">
							<Col>
								<Nav justify variant="tabs" defaultActiveKey="rooms" style={{ flexDirection: 'row', maxWidth: '400px' }}>
									<Nav.Item>
										<Nav.Link eventKey="audio">
											<FontAwesomeIcon icon={faFileAudio} />
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="items">
											<FontAwesomeIcon icon={faBookmark} /> <span id="items_found">{itemCount}</span>/<span id="items_total">{items.length}</span>
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
						</Row>
						<Row style={{ margin: 0 }}>
							<Col>
								<Tab.Content>
									<Tab.Pane transition={false} eventKey="audio">
										<iframe title="spotify-player" style={{ border: 'none', marginTop: '20px' }} src="https://open.spotify.com/embed/track/6avV0X27a8FKFbX8JOdHbc?utm_source=generator"
											width="100%" height="352" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
										<div>
											{dsp_links.map((dsp, idx) => (
												<div key={idx}>
													{dsp.from === 'fontawesome' ? (
														<a href={dsp.link} target="_blank" className="d-block text-white border border-light rounded-lg py-2 my-3 text-decoration-none" rel="noreferrer">
															<FontAwesomeIcon icon={dsp.icon} height={"25px"} className="mx-4" /> {dsp.title}
														</a>
													) : (
														<a href={dsp.link} target="_blank" className="d-block text-white text-decoration-none" rel="noreferrer">
															<Image className="mx-4" src={dsp.icon} alt="icon" width={20} height={20} />
														</a>
													)}
												</div>
											))}
										</div>
									</Tab.Pane>
									<Tab.Pane transition={false} eventKey="items">
										{items.length && (
											<>
												<div className="tab-pane-instr text-center">
													{userWon ? (
														<>
															You found all the Items!<br />
															<span role="button" onClick={() => setShowWin(true)}>Open Form!</span>
														</>
													) : (
														<>Can you find all the Hidden Items?<br /> Clicking the items in the 360 viewer will reveal them here.</>
													)}
												</div>
												{itemCount > 0 && (
													<div>
														[ <span style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }} onClick={() => { clearFoundItems() }}>Clear Found Items</span> ]
													</div>
												)}
											</>
										)}
										<div id="items">
											<Row>
												{items?.map((item, idx) => 
													item.visible ? (
														<Col key={idx} xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/items/${item.img_loc}`} alt="found item" className="item_thumb" title={item.title} fluid />
														</Col>
													) : (
														<Col key={idx} xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/hidden-item.png`} alt="hidden item" className="item_thumb" title={'Hidden Item'} fluid />
														</Col>
													)
												)}
											</Row>
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Navbar.Collapse>
			</Navbar>

			<div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000' }}>
				{toastList?.map(toast => {
					return (
						<Toast key={toast.id} onClose={() => removeToastFromList(toast.id)} show={true} delay={7000} autohide>
							<Toast.Header>
								<FontAwesomeIcon icon={faBookmark} className="me-3" />
								<strong className="mx-auto">{toast.message}</strong>
							</Toast.Header>
							<Toast.Body style={{ textAlign: 'left' }}>
								<img src={'images/items/' +toast.img} className="rounded me-2" style={{ height: '50px', width: '50px' }} alt="" />
								{toast.item_name}
							</Toast.Body>
						</Toast>
					)
				})}
			</div>

			<KuulaProject />
		</div>
	)
}