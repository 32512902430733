import React, { useEffect } from 'react';

export default function KuulaProject(props) {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe className="ku-embed" 
				title="Alice For President" 
				style={{ border: 'none' }} allowFullScreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" 
				src="https://tours.mindpower.dev/share/collection/7PDTQ?logo=-1&info=0&fs=0&vr=0&thumbs=-1&keys=0&inst=0"></iframe>
		</div>
	)

}

